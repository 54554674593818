import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Row, Col, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {
    endpointEnvioDocumento,
    endpointEnvioDocumentoFiltrar,
    endpointEnvioDocumentoVisualizar,
    nomeJWTUsuario,
    urlHost,
    urlHostBase
} from '../../../../utils/Constants';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import { useCookies } from 'react-cookie';
import { setMsgErro, setMsgInfo } from '../../../../utils/Utils';

const Documentos = () => {
  const [cookies, setCookie, removeCookie] = useCookies(nomeJWTUsuario);
  const [documentos, setDocumentos] = useState([]);
  const [selectedDocumento, setSelectedDocumento] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
  const [msgAlert, setMsgAlert] = useState('');
  const [filtroProtocolo, setFiltroProtocolo] = useState('');

  const handleViewDocumento = (documento) => {
    setSelectedDocumento(documento);
    setShowModal(true);
  };

  const handleDeleteDocumento = () => {
    axios.delete(urlHost + endpointEnvioDocumento + '/' + selectedDocumento.id, getAuthConfigHeaders(cookies))
      .then(response => {
          setShowDeleteConfirmationModal(false);
          if (response.status === 200 || response.status === 201) {
              if (response.data) {
                  setMsgInfo('Documento excluído com sucesso!', setMsgGeral);
                  loadEnvios();
              } else {
                  setMsgErro('Sem resposta do servidor. Tente novamente em alguns instantes.', setMsgGeral);
              }
          } else {
              setMsgErro('Erro: ' + response.data, setMsgGeral);
          }
      })
      .catch(error => {
          setShowDeleteConfirmationModal(false);
          if (error.response.data.length > 0) {
              setMsgErro('Erro: ' + error.response.data, setMsgGeral);
          } else {
              setMsgErro('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.', setMsgGeral);
          }
      });
  };

  const handleCloseModal = () => {
    handleVisualizar();
  };

  function loadEnvios() {
    axios.get(urlHost + endpointEnvioDocumento, getAuthConfigHeaders(cookies))
      .then(response => {
        if (response.status === 200) {
          setDocumentos(response.data);
        }
      }).catch(error => {
        setMsgGeral({ msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')' });
      });
  }

    function translateRequerimento(req) {
        const translations = {
            'CREDENCIAMENTO': 'Credenciamento de AGC',
            'HABILITACAO': 'Habilitação e Divergência'
        };
        return translations[req] || req;
    }

    function translateTipo(req) {
        const translations = {
            'RECUPERACAO_JUDICIAL': 'Recuperação Judicial',
            'FALENCIA': 'Falência',
            'PERICIA': 'Perícia',
            'PENHORA_DE_FATURAMENTO': 'Penhora de Faturamento',
            'INVENTARIO': 'Inventário'
        };
        return translations[req] || req;
    }

    const handleVisualizar = () => {
    if (selectedDocumento && selectedDocumento.id && !selectedDocumento.visualizado) {
        axios.put(urlHost + endpointEnvioDocumentoVisualizar, {id: selectedDocumento.id}, getAuthConfigHeaders(cookies))
            .then(response => {
                handleVisualizarResponse(response);
            })
            .catch(error => {
                handleVisualizarErrorResponse(error);
            });
    }else{
      setShowModal(false);
    }
  };

  const handleVisualizarResponse = (response) => {
      if (response.status === 200 || response.status === 201) {
          if (response.data) {
              loadEnvios();
              setShowModal(false);
          } else {
              setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
          }
      } else {
          setMsgAlert('Erro: ' + response.data);
      }
  }

  const handleVisualizarErrorResponse = (error) => {
      if (error.response.data) {
          setMsgAlert('Erro: ' + error.response.data);
      } else {
          setMsgAlert('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
      }
  }

  const showDeleteConfirmation = (documento) => {
    setSelectedDocumento(documento);
    setShowDeleteConfirmationModal(true);
  };

  useEffect(() => {
    loadEnvios();
  }, []);

  const formatarData = (data) => {
      const dataFormatada = new Date(data);
      const dia = String(dataFormatada.getDate()).padStart(2, '0');
      const mes = String(dataFormatada.getMonth() + 1).padStart(2, '0');
      const ano = dataFormatada.getFullYear();
      const hora = String(dataFormatada.getHours()).padStart(2, '0');
      const minutos = String(dataFormatada.getMinutes()).padStart(2, '0');
      const segundos = String(dataFormatada.getSeconds()).padStart(2, '0');
      return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
  };

  function filtrar() {
      if(filtroProtocolo !== ""){
          axios.get(urlHost + endpointEnvioDocumentoFiltrar + '/' + filtroProtocolo, getAuthConfigHeaders(cookies))
              .then(response => {
                  if (response.status === 200) {
                      setDocumentos([response.data]);
                  }
              }).catch(error => {
                  loadEnvios();
              });
      }else{
          loadEnvios();
      }
  }

    return (
        <div>
            <Row>
                <Col>
                    {msgGeral.msg.length > 0 ?
                        <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                            {msgGeral.msg}
                        </Alert>
                        : null
                    }
                </Col>
            </Row>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card admin-card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Documentos Enviados
                        </div>
                        <div className="card-body">
                            <Row style={{paddingBottom:'10px'}}>
                                <Col md={6}>
                                    <Form.Group controlId="filtroProtocolo">
                                        <Form.Label>Número de Protocolo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Digite o número de protocolo"
                                            value={filtroProtocolo}
                                            onChange={(e) => setFiltroProtocolo(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="d-flex align-items-end">
                                    <Button variant="primary" onClick={filtrar} className="ml-auto">Filtrar</Button>
                                </Col>
                            </Row>
                            <Table hover responsive className='table'>
                                <thead>
                                <tr>
                                    <th style={{ width: '70px' }}>ID</th>
                                    <th>Tipo de Processo</th>
                                    <th>Empresa</th>
                                    <th>Requerimento</th>
                                    <th style={{ width: '160px' }}>Ações</th>
                                </tr>
                                </thead>
                                <tbody>
                                {documentos.map((documento) => (
                                    <tr key={documento.id} className={documento.visualizado ? '' : 'documento-pendente'}>
                                        <td >{documento.id}</td>
                                        <td >{translateTipo(documento.tipoProcesso)}</td>
                                        <td >{documento.empresa ? documento.empresa.nome : ''}</td>
                                        <td >{translateRequerimento(documento.requerimento)}</td>
                                        <td>
                                            <Button variant="link" onClick={() => handleViewDocumento(documento)} className='btn-visualizar'>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                            <Button
                                                variant="link"
                                                onClick={() => showDeleteConfirmation(documento)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                            {documento.arquivo ?
                                                <Button variant="link" href={urlHostBase+'/'+documento.arquivo.linkArquivo} target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                                : null
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Documento {selectedDocumento.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedDocumento && (
                        <div>
                            <Row>
                                <Col>
                                    {msgAlert.length > 0 ?
                                        <Alert variant='danger'>
                                            {msgAlert}
                                        </Alert>
                                        : null
                                    }
                                </Col>
                            </Row>
                            <p><strong>Número de protocolo:</strong> {selectedDocumento.numeroProtocolo}</p>
                            <p><strong>Data de Envio:</strong> {formatarData(selectedDocumento.dataEnvio)}</p>
                            <p><strong>Tipo de Processo:</strong> {translateTipo(selectedDocumento.tipoProcesso)}</p>
                            <p><strong>Empresa:</strong> {selectedDocumento.empresa ? selectedDocumento.empresa.nome : ''}</p>
                            <p><strong>Requerimento:</strong> {translateRequerimento(selectedDocumento.requerimento)}</p>
                            <p><strong>Nome do Credor:</strong> {selectedDocumento.nomeCredor}</p>
                            <p><strong>CPF/CNPJ:</strong> {selectedDocumento.cpfCnpj}</p>
                            <p><strong>Representante Legal:</strong> {selectedDocumento.representanteLegal}</p>
                            <p><strong>Documento do Representante:</strong> {selectedDocumento.documentoRepresentante}</p>
                            <p><strong>Email:</strong> {selectedDocumento.email}</p>
                            <p><strong>WhatsApp:</strong> {selectedDocumento.whatsapp}</p>
                            <p><strong>Observação:</strong> {selectedDocumento.obs}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {selectedDocumento.arquivo ?
                        <Button variant="primary" href={urlHostBase+'/'+selectedDocumento.arquivo.linkArquivo} target="_blank" rel="noreferrer">
                            Baixar Anexo
                        </Button>
                        : null
                    }

                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir o documento <b>{selectedDocumento.id}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteDocumento()}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Documentos;