import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Container, Row, Col, Alert, Accordion, Card, Modal, Form} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { endpointProcesso, setFooterDark, urlHost, urlHostBase } from '../../../../utils/Constants';
import axios from 'axios';
import { formataNomeArquivo } from '../../../../utils/Utils';
import InputMask from "react-input-mask";
import Nav from "react-bootstrap/Nav";

const ProcessoEmpresa = () => {
    const [processo, setProcesso] = useState({
        prazoApresentacao:{
            habilitacoesDivergenciasAdm: '',
            impugnacaoCredito: '',
            objecoesPlanoRecupJudicial: ''
        },
        mensagemAlerta:{
            titulo: '',
            descricao: '',
            ativo: false
        }
    });
    const [pecasProcessuais, setPecasProcessuais] = useState([]);
    const [relatorioAtividade, setRelatorioAtividade] = useState([]);
    const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
    let {idProcesso} = useParams();
    const [optionSelected, setOptionSelected] = useState('pecas');

    const [showModal, setShowModal] = useState(false);
    const textareaRef = useRef(null);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const loadProcesso = useCallback(() => {
        axios.get(urlHost + endpointProcesso+'/'+idProcesso)
        .then(response => {
            if (response.status === 200) {
                preparaDados(response.data);
            }
        }).catch(error => {
            setMsgGeral({msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. ('+error.response+')'});
        });
    },[idProcesso]);

    function preparaDados(retorno){
        try{
            setProcesso(retorno);
            let pecas = [];
            let contas = [];

            if(retorno.arquivos && retorno.arquivos.length>0){
                for(let i=0; i<retorno.arquivos.length; i++) {
                    if(retorno.arquivos[i].tipoArquivoProcesso === 'PECAS'){
                        pecas.push(retorno.arquivos[i]);
                    }else if(retorno.arquivos[i].tipoArquivoProcesso === 'RELATORIO_ATIVIDADE'){
                        contas.push(retorno.arquivos[i]);
                    }
                }
            }
            setPecasProcessuais(pecas);
            setRelatorioAtividade(contas);

            if (retorno.mensagemAlerta.ativo === true) {
                setShowModal(true);
            }

        }catch(e){
            setMsgGeral({msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. ('+e+')'});
        }
    }

    function formatDate(dateString) {
        if (!dateString) return '';

        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    }

    useEffect(() => {
        setFooterDark(true);
        loadProcesso();
    }, [loadProcesso]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [processo.mensagemAlerta.descricao]);

    const handleOptionClick = (option) => {
        setOptionSelected(option);
    };

    return (
        <>
            <div className="page-header consulta-processos">	
                <div className="container clr page-header-inner">
                    <h1 className="page-header-title clr">{processo.empresa ? processo.empresa.nome : ''}</h1>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>
        
            <Container className='container-processo'>
                <Row>
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                        <Modal.Header className='modal-header-custom' closeButton>
                            <Modal.Title className='modal-title-custom'>{processo.mensagemAlerta.titulo}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{padding: 0}}>
                            <Form.Control className="msgAlertaProcesso"
                                as="textarea"
                                readOnly
                                value={processo.mensagemAlerta.descricao}
                                ref={textareaRef}
                            />
                        </Modal.Body>
                    </Modal>
                    <Accordion defaultActiveKey="0" className='custom-accordion'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <strong className='card-processo-label'>Processo: <InputMask
                                                    mask="9999999-99.9999.9.99.9999"
                                                    maskChar={null}
                                                    value={processo.numProcesso}
                                                    readOnly
                                                    style={{border: 'none',background: 'none', minWidth: '400px'}}
                                                /></strong>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <strong className='card-processo-label'>Comarca:</strong> {processo.comarca}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <strong className='card-processo-label'>Data do Pedido:</strong> {processo.empresa?.resumo}
                                            </Col>
                                        </Row>
                                        {processo.tipoProcesso === "FALENCIA" && (
                                            <Row>
                                                <Col>
                                                    <strong className='card-processo-label'>Data da decretação da falência:</strong> {formatDate(processo.prazoApresentacao.objecoesPlanoRecupJudicial)}
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col>
                                                <strong className='card-processo-label'>Fase atual:</strong>  {processo.faseAtual}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <strong className='card-processo-label'>Prazo final para apresentação de habilitações e divergências administrativas:</strong> {formatDate(processo.prazoApresentacao.habilitacoesDivergenciasAdm)}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <strong className='card-processo-label'>Prazo final para apresentação de impugnação de crédito:</strong> {formatDate(processo.prazoApresentacao.impugnacaoCredito)}
                                            </Col>
                                        </Row>
                                        {processo.tipoProcesso !== "FALENCIA" && (
                                            <Row>
                                                <Col>
                                                    <strong className='card-processo-label'>Prazo final para apresentação de objeções ao plano de recuperação judicial:</strong> {formatDate(processo.prazoApresentacao.objecoesPlanoRecupJudicial)}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                <Row>
                    <Col>
                        {msgGeral.msg.length > 0 ?
                            <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                                {msgGeral.msg}
                            </Alert>
                            : null
                        }
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col>
                        {processo.arquivos && (
                            <Card style={{borderColor: '#BEAA76'}}>
                                <Card.Header className="card-header-arquivos">
                                    <Nav fill variant="tabs" defaultActiveKey="#pecas" onSelect={(selectedKey) => setOptionSelected(selectedKey.slice(1))}>
                                        <Nav.Item>
                                            <Nav.Link as="button" onClick={() => handleOptionClick('pecas')} className={`documentos-processo nav-link-button ${optionSelected === 'pecas' ? 'active' : ''}`}>Peças Processuais</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as="button" onClick={() => handleOptionClick('relatorio-atividade')} className={`documentos-processo nav-link-button ${optionSelected === 'relatorio-atividade' ? 'active' : ''}`}>Relatórios de Atividade</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Header>
                                <Card.Body className="class-body">
                                    {optionSelected === 'pecas' ? (
                                        pecasProcessuais.map((arquivo, index) => (
                                            <div key={arquivo.id} className={`${index % 2 === 0 ? 'linha-par' : 'linha-impar'} ${index === pecasProcessuais.length - 1 ? 'ultima-linha' : ''}`}>
                                                <a className="animated fadeInLeft arquivo-processo" href={urlHostBase+'/'+arquivo.linkArquivo} target="_blank" rel="noreferrer">{formataNomeArquivo(arquivo.nome)}</a>
                                            </div>
                                        ))
                                    ) : (
                                        relatorioAtividade.map((arquivo, index) => (
                                            <div key={arquivo.id} className={`${index % 2 === 0 ? 'linha-par' : 'linha-impar'} ${index === relatorioAtividade.length - 1 ? 'ultima-linha' : ''}`}>
                                                <a className="animated fadeInLeft arquivo-processo" href={urlHostBase+'/'+arquivo.linkArquivo} target="_blank" rel="noreferrer">{formataNomeArquivo(arquivo.nome)}</a>
                                            </div>
                                        ))
                                    )}
                                    {(optionSelected === 'pecas' && pecasProcessuais.length === 0) || (optionSelected === 'relatorio-atividade' && relatorioAtividade.length === 0) ? (
                                        <div className="animated fadeInLeft" style={{textAlign: 'center'}}>Nenhum arquivo encontrado.</div>
                                    ) : null}
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ProcessoEmpresa;