import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { endpointUsuario, nomeJWTUsuario, urlHost } from "../../../../utils/Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import { setMsgErro, setMsgInfo } from '../../../../utils/Utils';

function Usuarios() {
    const [cookies, setCookie, removeCookie] = useCookies(nomeJWTUsuario);
    const [users, setUsers] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({ nome: '', login: '', senha: '', confirmarSenha: '' });
    const [msgAlert, setMsgAlert] = useState('');
    const [msgGeral, setMsgGeral] = useState({msg: '', variant: 'danger'});

    const handleAddUser = () => {
        setMsgAlert('');
        setShowModal(true);
        setSelectedUser({ nome: '', login: '', senha: '', confirmarSenha: '' });
    };

    const handleEditUser = (user) => {
        setMsgAlert('');
        user.senha = '';
        user.confirmarSenha = '';
        setShowModal(true);
        setSelectedUser(user);
    };

    const handleSaveUser = () => {
        setMsgAlert('');

        if (!selectedUser.nome || selectedUser.nome.length === 0) {
            setMsgAlert('Informe o nome do usuário!');
            return;
        }

        if (!selectedUser.login || selectedUser.login.length === 0) {
            setMsgAlert('Informe o login do usuário!');
            return;
        }

        if (!selectedUser.senha || selectedUser.senha.length === 0) {
            setMsgAlert('Informe a senha do usuário!');
            return;
        }

        if (selectedUser.senha !== selectedUser.confirmarSenha) {
            setMsgAlert('As senhas informadas diferem!');
            return;
        }

        let newUser = {
            id: selectedUser.id,
            nome: selectedUser.nome,
            login: selectedUser.login,
            senha: selectedUser.senha
        }

        if (selectedUser.id) {
            axios.put(urlHost + endpointUsuario, newUser, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleUsuarioResponse(response);
                })
                .catch(error => {
                    handleUsuarioErrorResponse(error);
                });
        } else {
            axios.post(urlHost + endpointUsuario, newUser, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleUsuarioResponse(response);
                })
                .catch(error => {
                    handleUsuarioErrorResponse(error);
                });
        }

    };

    const handleUsuarioResponse = (response) => {
        if (response.status === 200 || response.status === 201) {
            if (response.data) {
                setMsgInfo('Salvo com sucesso!', setMsgGeral);
                setShowModal(false);
                loadUsers();
            } else {
                setMsgAlert('Sem resposta do servidor. Tente novamente em alguns instantes.');
            }
        } else {
            setMsgAlert('Erro: ' + response.data);
        }
    }

    const handleUsuarioErrorResponse = (error) => {
        if (error.response.data.length > 0) {
            setMsgAlert('Erro: ' + error.response.data);
        } else {
            setMsgAlert('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.');
        }
    }

    const handleDeleteUser = () => {
        axios.delete(urlHost + endpointUsuario + '/' + selectedUser.id, getAuthConfigHeaders(cookies))
            .then(response => {
                setShowDeleteConfirmationModal(false);
                if (response.status === 200 || response.status === 201) {
                    if (response.data) {
                        setMsgInfo('Usuário excluído com sucesso!', setMsgGeral);
                        loadUsers();
                    } else {
                        setMsgErro('Sem resposta do servidor. Tente novamente em alguns instantes.', setMsgGeral);
                    }
                } else {
                    setMsgErro('Erro: ' + response.data, setMsgGeral);
                }
            })
            .catch(error => {
                setShowDeleteConfirmationModal(false);
                if (error.response.data.length > 0) {
                    setMsgErro('Erro: ' + error.response.data, setMsgGeral);
                } else {
                    setMsgErro('Ocorreu um erro desconhecido. Tente novamente em alguns instantes.', setMsgGeral);
                }
            });
    };

    function loadUsers() {
        axios.get(urlHost + endpointUsuario, getAuthConfigHeaders(cookies))
            .then(response => {
                if (response.status === 200) {
                    setUsers(response.data);
                }
            }).catch(error => {
                setMsgErro('Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')', setMsgGeral);
            });;
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const showDeleteConfirmation = (user) => {
        setSelectedUser(user);
        setShowDeleteConfirmationModal(true);
    };

    useEffect(() => {
        loadUsers();
    }, []);


    return (
        <Container>
            <Row>
                <Col>
                    {msgGeral.msg.length > 0 ?
                        <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                            {msgGeral.msg}
                        </Alert>
                        : null
                    }
                </Col>
            </Row>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <div className="card admin-card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Usuários
                            <Button variant="primary" onClick={handleAddUser}>Criar Usuário</Button>
                        </div>
                        <div className="card-body">
                            <Table hover responsive className='table'>
                                <thead>
                                    <tr>
                                        <th style={{ width: '70px' }}>ID</th>
                                        <th>Nome</th>
                                        <th>Login</th>
                                        <th style={{ width: '100px' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.id}</td>
                                            <td>{user.nome}</td>
                                            <td>{user.login}</td>
                                            <td>
                                                <Button variant="link" onClick={() => handleEditUser(user)}><FontAwesomeIcon icon={faEdit} /></Button>
                                                {user.id ?
                                                    <Button variant="link" type='button' onClick={() => showDeleteConfirmation(user)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Dados do Usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-admin'>
                        <Container>
                            <Row>
                                <Col>
                                    {msgAlert.length > 0 ?
                                        <Alert variant='danger'>
                                            {msgAlert}
                                        </Alert>
                                        : null
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="nome" className='form-admin-group'>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedUser.nome}
                                            disabled={selectedUser.id}
                                            autoComplete="off"
                                            onChange={(e) => setSelectedUser({ ...selectedUser, nome: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="login" className='form-admin-group'>
                                        <Form.Label>Login</Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled={selectedUser.id}
                                            value={selectedUser.login}
                                            autoComplete="off"
                                            onChange={(e) => setSelectedUser({ ...selectedUser, login: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="senha" className='form-admin-group'>
                                        <Form.Label>Senha</Form.Label>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            value={selectedUser.senha}
                                            autoComplete="off"
                                            onChange={(e) => setSelectedUser({ ...selectedUser, senha: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formConfirmPassword" className='form-admin-group'>
                                        <Form.Label>Confirme a Senha</Form.Label>
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            value={selectedUser.confirmarSenha}
                                            onChange={(e) => setSelectedUser({ ...selectedUser, confirmarSenha: e.target.value })}
                                            autoComplete="off"
                                        />
                                        <Form.Check
                                            className="mt-2"
                                            type="checkbox"
                                            label="Mostrar senha"
                                            checked={showPassword}
                                            onChange={toggleShowPassword}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type='button' onClick={() => setShowModal(false)}>Cancelar</Button>
                    <Button variant="primary" type='button' onClick={handleSaveUser}>Salvar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir o usuário <b>{selectedUser.login}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteUser()}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Usuarios;