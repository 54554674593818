import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Alert, Button, Form, Modal} from 'react-bootstrap';
import { endpointArquivoEnvio, endpointEmpresa, endpointEnvioDocumento, setFooterDark, urlHost } from '../../../../utils/Constants';
import axios from 'axios';
import { setMsgErro, validateEmail } from '../../../../utils/Utils';
import InputMask from "react-input-mask";

const EnvioDocumentos = () => {
    const emptyForm = {
        tipoProcesso: "",
        empresa: {},
        requerimento: "",
        nomeCredor: "",
        cpfCnpj: "",
        representanteLegal: "",
        documentoRepresentante: "",
        email: "",
        whatsapp: "",
        obs: "",
        arquivo: {},
        numeroProtocolo: "",
        dataEnvio: ""
    }
    const [documento, setDocumento] = useState(emptyForm);
    const [empresas, setEmpresas] = useState([]);
    const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
    const [numProtocolo, setNumProtocolo] = useState({msg: ''});
    const [file, setFile] = useState();

    const handleFileChange = (event) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
            setDocumento({
                ...documento,
                arquivo: {},
            });
        }
    };

    function loadEmpresas() {
        axios.get(urlHost + endpointEmpresa)
        .then(response => {
            if (response.status === 200) {
                setEmpresas(response.data);
            }
        }).catch(error => {
            setMsgErro('Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. ('+error.response+')', setMsgGeral);
        });
    }

    function handleChangeEmpresa(idEmpresa){
        if(empresas){
            for(let i=0; i<empresas.length; i++){
                if(empresas[i].id == idEmpresa){
                    setDocumento({
                        ...documento,
                        empresa: empresas[i],
                    });
                    break;
                }
            }
        }
    }

    function handleEnviar() {
        setMsgErro('', setMsgGeral);
        let erro = false;
        if(documento){
            if(!documento.tipoProcesso){
                setMsgErro('O preenchimento do campo Tipo de processo é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.empresa || !documento.empresa.id){
                setMsgErro('O preenchimento do campo Empresa é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.requerimento){
                setMsgErro('O preenchimento do campo Requerimento é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.nomeCredor){
                setMsgErro('O preenchimento do campo Nome do Credor é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.cpfCnpj){
                setMsgErro('O preenchimento do campo CPF/CNPJ do Credor é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.email){
                setMsgErro('O preenchimento do campo E-mail é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.whatsapp){
                setMsgErro('O preenchimento do campo WhatsApp é obrigatório!', setMsgGeral);
                erro = true;
            }
            else if(!documento.obs){
                setMsgErro('O preenchimento do campo Observações é obrigatório!', setMsgGeral);
                erro = true;
            }

            if(documento.email && !validateEmail(documento.email)){
                setMsgErro('Informe um e-mail válido!', setMsgGeral);
                erro = true;
            }
        }else{
            erro = true;
        }

        if(erro){
            window.scrollTo(0, 0);
        }else{
            handleUpload();
        }
    }

    async function handleUpload(){
        if(!documento.arquivo || !documento.arquivo.id){
            let formData = new FormData();
            let headers = {'Content-Type': 'multipart/form-data'};

            if(file){
                formData.append('arquivo', file);
            }else{
                setMsgErro('É necessário selecionar um arquivo para enviar.', setMsgGeral);
                return;
            }

            try{
                const response1 = await axios.post(urlHost + endpointArquivoEnvio, formData, {headers: headers});
                if(response1.status === 201 || response1.status === 200) {
                    setDocumento({
                        ...documento,
                        arquivo: response1.data,
                    });

                    handleSaveDocumento(response1.data);
                }
            }catch(e){
                if(e.response && e.response.data){
                    setMsgGeral({msg: 'Erro ao enviar arquivo: '+e.response.data});
                }else{
                    setMsgGeral({msg: 'Erro ao enviar arquivo: '+e.message});
                }
            }
        }else{
            handleSaveDocumento();
        }
    }

    function handleSaveDocumento(arquivo){
        if(arquivo){
            documento.arquivo = arquivo;
        }
        if (documento) {
            axios.post(urlHost + endpointEnvioDocumento, documento)
                .then(response => {
                    handleDocResponse(response);
                })
                .catch(error => {
                    handleDocErrorResponse(error);
                });
        }
    }

    const handleDocResponse = (response) => {
        if (response.status === 200 || response.status === 201) {
            if (response.data) {
                setDocumento(emptyForm);
                setMsgGeral({msg: 'Dados enviados com sucesso!', variant: 'info'});
                setNumProtocolo({msg: response.data.numeroProtocolo});
                setShowModal(true);
            } else {
                setMsgGeral({msg:'Sem resposta do servidor. Tente novamente em alguns instantes.'});
            }
        } else {
            setMsgGeral({msg:'Erro: ' + response.data});
        }
    }

    const handleDocErrorResponse = (error) => {
        window.scrollTo(0,0);
        if (error.response.data.length > 0) {
            setMsgGeral({msg:'Erro: ' + error.response.data});
        } else {
            setMsgGeral({msg:'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.'});
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 500);

    }
    const handleShowModal = () => setShowModal(true);

    const copiarNumeroProtocolo = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000);
        navigator.clipboard.writeText(numProtocolo.msg);
    };

    useEffect(() => {
        setFooterDark(true);
        loadEmpresas();
    }, []);

    return (
        <>
            <div className="page-header envio-documentos">	
                <div className="container clr page-header-inner">
                    <h1 className="page-header-title clr">Envio de Documentos</h1>
                </div>
                <span className="background-image-page-header-overlay"></span>
            </div>
        
            <Container className='container-envio'>
                <Row className="justify-content-md-center">
                    <Form className='form-envio' >
                        <Row>
                            <Col>
                                {msgGeral.msg.length > 0 && !showModal ?
                                    <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'}>
                                        {msgGeral.msg}
                                    </Alert>
                                    : null
                                }
                            </Col>
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header style={{ backgroundColor: '#02365E', color: '#FFFFFF', border: 'none'}} closeButton>
                                    <Modal.Title>{msgGeral.msg}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Seu número de protocolo é: {numProtocolo.msg}<br></br>
                                    Recomendamos que tire um print ou anote o número acima.
                                    {showMessage && (
                                        <Alert variant="success" style={{ marginTop: '10px' }} onClose={() => setShowMessage(false)} dismissible>
                                            Número de protocolo copiado com sucesso!
                                        </Alert>
                                    )}
                                </Modal.Body>
                                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button style={{ backgroundColor: '#BDA976', borderColor: '#BDA976' }} onClick={copiarNumeroProtocolo}>
                                        Copiar Número de Protocolo
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Row>
                        <div className="fields-required">
                            Campos marcados com <span className="req-symbol">*</span> são de preenchimento obrigatório
                            e, no caso de documentos, somente é possível o envio de um anexo.
                        </div>
                        <Form.Group controlId="tipoProcesso" className='form-group'>
                            <Form.Label><br></br>Tipo de Processo: <span className="req-symbol">*</span></Form.Label>
                            <Form.Select
                                placeholder="Selecione uma Empresa" required
                                value={documento.tipoProcesso}
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        tipoProcesso: e.target.value,
                                    });
                                }}>
                                <option value=''></option>
                                <option value='RECUPERACAO_JUDICIAL'>Recuperação Judicial</option>
                                <option value='FALENCIA'>Falência</option>
                                <option value='PERICIA'>Perícia</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="nomeEmpresa" className='form-group'>
                        <Form.Label>Empresa: <span className="req-symbol">*</span></Form.Label>
                            <Form.Select
                                placeholder="Selecione uma Empresa" required
                                value={documento.empresa ? documento.empresa.id : ''}
                                onChange={(e) => {
                                    handleChangeEmpresa(e.target.value)
                                }}>
                                <option value=''></option>
                                {empresas.map((empresa) => (
                                    <option key={empresa.id} value={empresa.id}>{empresa.nome}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="requerimento" className='form-group'>
                            <Form.Label>Requerimento: <span className="req-symbol">*</span></Form.Label>
                            <Form.Select
                                placeholder="Selecione uma Empresa" required
                                value={documento.requerimento}
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        requerimento: e.target.value,
                                    });
                                }}>
                                <option value=''></option>
                                <option value='CREDENCIAMENTO'>Credenciamento de AGC</option>
                                <option value='HABILITACAO'>Habilitação e Divergência</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="nomeCredor" className='form-group'>
                            <Form.Label>Nome do Credor: <span className="req-symbol">*</span></Form.Label>
                            <Form.Control type="text" value={documento.nomeCredor} placeholder="Digite o nome do credor" 
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        nomeCredor: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="cpfCnpjCredor" className='form-group'>
                            <Form.Label>CPF/CNPJ do Credor: <span className="req-symbol">*</span></Form.Label>
                            <Form.Control type="text" value={documento.cpfCnpj} placeholder="Digite o CPF ou CNPJ do credor"
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        cpfCnpj: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="representanteLegal" className='form-group'>
                            <Form.Label>Representante Legal: </Form.Label>
                            <Form.Control type="text" value={documento.representanteLegal} placeholder="Digite o representante legal" 
                                 onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        representanteLegal: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="documentoRepresentante" className='form-group'>
                            <Form.Label>Documento do Representante Legal (RG ou CPF): </Form.Label>
                            <Form.Control type="text" value={documento.documentoRepresentante} placeholder="Digite o documento do representante legal" 
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        documentoRepresentante: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="email" className='form-group'>
                            <Form.Label>E-mail: <span className="req-symbol">*</span></Form.Label>
                            <Form.Control type="email" value={documento.email} placeholder="Digite o e-mail" 
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        email: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="whatsapp" className='form-group'>
                            <Form.Label>WhatsApp: <span className="req-symbol">*</span></Form.Label>
                            <InputMask
                                mask="(99) 99999-9999"
                                maskPlaceholder=""
                                value={documento.whatsapp}
                                placeholder="Digite o número de WhatsApp"
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        whatsapp: e.target.value,
                                    });
                                }}
                            >
                                {(inputProps) => <Form.Control type="text" {...inputProps} />}
                            </InputMask>

                        </Form.Group>

                        <Form.Group controlId="observacoes" className='form-group'>
                            <Form.Label>Observações: <span className="req-symbol">*</span></Form.Label>
                            <Form.Control as="textarea" value={documento.obs} rows={3} placeholder="Digite as observações" 
                                onChange={(e) => {
                                    setDocumento({
                                        ...documento,
                                        obs: e.target.value,
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="uploadArquivos" className='form-group'>
                            <Form.Label>Upload de Arquivo <span className="req-symbol">*</span> <span className="req-symbol">ATENÇÃO</span>
                                <br></br>Os documentos devem ser enviados em apenas um arquivo, no formato doc, docx ou pdf</Form.Label>
                            <Form.Control type="file" accept=".doc, .docx, .pdf" onChange={handleFileChange}/>
                            {!file ? 
                                <Form.Text className="text-muted">
                                    Selecione um arquivo
                                </Form.Text>    
                                : null
                            }
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={() => {handleEnviar();}}>
                            Enviar
                        </Button>
                    </Form>
                </Row>
            </Container>
        </>
    );
};

export default EnvioDocumentos;