import React from 'react'
import { BrowserRouter } from  'react-router-dom'
import Content from './Content';
import '../../../resources/css/home.css'
import '../../../resources/css/fonts.css'
import '../../../resources/css/admin/master.css'
import '../../../resources/css/admin/admin.css'
import '../../../resources/css/animate.css'
import { CookiesProvider } from 'react-cookie';

const App = props => (
  <div className="App">
    <CookiesProvider>
      <BrowserRouter>
        <Content />
      </BrowserRouter>
    </CookiesProvider>
  </div>
);

export default App;
