import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {endpointAdvogados, setFooterDark, urlHost, urlHostBase} from "../../../../utils/Constants";
import placeholderEmpresa from "../../../../resources/img/company-placeholder.webp";
import {Modal, Row, Col} from "react-bootstrap";
import {formatPhoneNumber, isValidPhoneNumber} from "../../../../utils/Utils";
import {ReactComponent as WhatsAppIcon} from '../../../../resources/img/whatsapp-footer.svg';
import {ReactComponent as EnvelopeIcon} from '../../../../resources/img/envelope.svg';

function Equipe(){
    const [advogados, setAdvogados] = useState([]);
    const [showModalDescricao, setShowModalDescricao] = useState(false);
    const [selectedAdvogado, setSelectedAdvogado] = useState(null);

    const handleShowModalDescricao = (advogado) => {
        setSelectedAdvogado(advogado);
        setShowModalDescricao(true);
    }

    const handleCloseModalDescricao = () => {
        setSelectedAdvogado(null);
        setShowModalDescricao(false);
    }

    useEffect(() => {
        setFooterDark(true)

        const loadAdvogados = () => {
            axios.get(urlHost + endpointAdvogados)
                .then(response => {
                    if (response.status === 200) {
                        setAdvogados(response.data);
                    }
                }).catch(error => {
                console.log(error);
            });
        }

        loadAdvogados();

    }, []);


    return (
        <>
            <div id='equipe' className="site-section fill-height-rows pattern-left">
                <Row className="text-align-center">
                    <div className="site-section-title-wrapper">
                        <div className="site-section-title stroked-shadow">A EQUIPE</div>
                        <span className="title-stroked">Equipe</span>
                    </div>
                </Row>
                <Container className='container-equipe'>
                    <p className="site-section-text mt-3">
                        Conheça os nossos profissionais e suas experiências.
                        <br/>Com uma equipe multidisciplinar buscamos encontrar a melhor solução para cada caso.
                    </p>
                    <Row className="g-4">
                        {advogados.map(advogado => (
                            <Col xs={12} sm={12} md={3} lg={3} className="text-align-center" key={advogado.id}>
                                <div className="card-equipe mt-4 w-100">
                                    <figure className="card-equipe-figure mt-3 mb-3" onClick={() => handleShowModalDescricao(advogado)}>
                                        <img
                                            src={advogado.foto?.linkArquivo ? `${urlHostBase}/${advogado.foto.linkArquivo}` : placeholderEmpresa}
                                            alt={advogado.foto?.linkArquivo ? `Foto de ${advogado.nome}` : "Foto Advogado"}
                                            className='card-equipe-img'
                                        />
                                        <figcaption className="card-equipe-content">
                                            <h1>{advogado.nome}</h1>
                                        </figcaption>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>

            <Modal className="modal-xl" show={showModalDescricao} onHide={handleCloseModalDescricao}
                   centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2 style={{color: '#02365E'}}>Nossa Equipe</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mt-4 mb-3'>
                    <Row>
                        <Col sm={12} md={12} lg={4}>
                            <figure className="card-equipe-figure">
                                {selectedAdvogado?.foto.linkArquivo ?
                                    <img src={urlHostBase + '/' + selectedAdvogado?.foto.linkArquivo} alt={`Foto de ${selectedAdvogado?.nome}`} className='card-equipe-img'/>
                                    :
                                    <img src={placeholderEmpresa} alt="Foto Advogado" className='card-equipe-img'/>
                                }
                            </figure>
                        </Col>
                        <Col sm={12} md={12} lg={8}>
                            <Row>
                                <Col sm={12} md={12} lg={6}>
                                    <strong>{selectedAdvogado?.nome}</strong>
                                </Col>
                                <Col sm={12} md={12} lg={6}>
                                    <p><strong>OAB:</strong> {selectedAdvogado?.oab}</p>
                                </Col>
                            </Row>

                            <br></br>

                            <p className="descricao-advogado">{selectedAdvogado?.descricao}</p>
                            <br></br>

                            <Row>
                                <Col>
                                    {isValidPhoneNumber(selectedAdvogado?.celular) && (
                                        <div id="whatsappAdvogado" className="mb-1">
                                            <a href={`https://wa.me/${encodeURIComponent(`${selectedAdvogado?.celular}`)}`}
                                               target="_blank" rel="noopener noreferrer"
                                               style={{textDecoration: 'none', color: 'inherit'}}>
                                                <WhatsAppIcon/>
                                                <span className="ms-2">{formatPhoneNumber(selectedAdvogado.celular)}</span>
                                            </a>
                                        </div>
                                    )}
                                </Col>
                                <Col>
                                    <div id="emailAdvogado" className="mb-1">
                                        <a href={`mailto:${selectedAdvogado?.email}`}
                                           style={{textDecoration: 'none', color: 'inherit'}}>
                                            <EnvelopeIcon/>
                                            <span className="ms-2">{selectedAdvogado?.email}</span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Equipe;