import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Modal, Form, Col, Row, Alert, Accordion } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {
    endpointProcesso,
    endpointEmpresa,
    endpointArquivoProcesso,
    nomeJWTUsuario,
    urlHost,
    extPermitidasProcesso,
    urlHostBase,
    repProcessos,
    endpointArquivo,
    endpointMensagemAlerta
} from "../../../../utils/Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { formataNomeArquivo, setMsgErro } from '../../../../utils/Utils';

function Processos() {
    const processoBase = {
        id: '',
        numProcesso: '',
        comarca: '',
        empresa: {},
        tipoProcesso: 'RECUPERACAO_JUDICIAL',
        faseAtual: '',
        mensagemAlerta: {
            tituloMensagemAlerta: '',
            descricaoMensagemAlerta: '',
            ativoMensagemAlerta: ''
        },
        arquivos: [],
        prazoApresentacao: {
            habilitacoesDivergenciasAdm: new Date(),
            impugnacaoCredito: new Date(),
            objecoesPlanoRecupJudicial: new Date()
        }
    };
    const [processos, setProcessos] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showMensagemAlertaModal, setShowMensagemAlertaModal] = useState(false);
    const [showFileDeleteConfirmationModal, setShowFileDeleteConfirmationModal] = useState(false);
    const [selectedProcesso, setSelectedProcesso] = useState(processoBase);
    const [selectedArquivo, setSelectedArquivo] = useState({});
    const [selectedPecas, setSelectedPecas] = useState([]);
    const [selectedRelatorioAtividade, setSelectedRelatorioAtividade] = useState([]);

    const [selectedFilesPecas, setSelectedFilesPecas] = useState([]);
    const [selectedFilesRelatorioAtividade, setSelectedFilesRelatorioAtividade] = useState([]);

    const [pecasProcessuaisFiles, setPecasProcessuaisFiles] = useState([]);
    const [relatorioAtividadeFiles, setRelatorioAtividadeFiles] = useState([]);
    const [msgGeral, setMsgGeral] = useState({ msg: '', variant: 'danger' });
    const [cookies] = useCookies(nomeJWTUsuario);

    const handleAddProcesso = () => {
        setShowModal(true);
        resetSelectedProcesso();
    };

    const handleEditProcesso = (processo) => {
        setShowModal(true);
        setSelectedProcesso(processo);
        resetFilesLists();
        preparaDados(processo);
    };

    function preparaDados(processo){
        try{
            let pecas = [];
            let contas = [];

            if(processo.arquivos && processo.arquivos.length>0){
                for(let i=0; i<processo.arquivos.length; i++) {
                    if(processo.arquivos[i].tipoArquivoProcesso === 'PECAS'){
                        pecas.push(processo.arquivos[i]);
                    }else if(processo.arquivos[i].tipoArquivoProcesso === 'RELATORIO_ATIVIDADE'){
                        contas.push(processo.arquivos[i]);
                    }
                }
            }

            setSelectedPecas(pecas);
            setSelectedRelatorioAtividade(contas);
        }catch(e){
            setMsgGeral({msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. ('+e+')'});
        }
    }

    const handleDeleteProcesso = () => {
        axios.delete(urlHost + endpointProcesso + '/' + selectedProcesso.id, getAuthConfigHeaders(cookies))
            .then(response => {
                setShowDeleteConfirmationModal(false);
                if (response.status === 200 || response.status === 201) {
                    if (response.data) {
                        setMsgGeral({ msg: 'Processo excluído com sucesso!', variant: 'info' });
                        loadProcessos();
                    } else {
                        setMsgGeral({ msg: 'Sem resposta do servidor. Tente novamente em alguns instantes.' });
                    }
                } else {
                    setMsgGeral({ msg: 'Erro: ' + response.data });
                }
            })
            .catch(error => {
                setShowDeleteConfirmationModal(false);
                if (error.response.data.length > 0) {
                    setMsgGeral({ msg: 'Erro: ' + error.response.data });
                } else {
                    setMsgGeral({ msg: 'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.' });
                }
            });
    };

    const showDeleteConfirmation = (processo) => {
        setSelectedProcesso(processo);
        setShowDeleteConfirmationModal(true);
    };

    const showFileDeleteConfirmation = (nome, id, tipo, index) => {
        let arq = {nome: nome, id: id, tipo: tipo, index: index};
        setSelectedArquivo(arq);
        setShowFileDeleteConfirmationModal(true);
    };

    const showMensagemAlerta = (processo) => {
        setSelectedProcesso(processo);
        setShowMensagemAlertaModal(true);
    };

    function loadEmpresas() {
        axios.get(urlHost + endpointEmpresa, getAuthConfigHeaders(cookies))
            .then(response => {
                if (response.status === 200) {
                    setEmpresas(response.data);
                }
            }).catch(error => {
                setMsgGeral({ msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')' });
            });
    }

    function loadProcessos() {
        axios.get(urlHost + endpointProcesso, getAuthConfigHeaders(cookies))
            .then(response => {
                if (response.status === 200) {
                    setProcessos(response.data);
                }
            }).catch(error => {
                setMsgGeral({ msg: 'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes. (' + error.response + ')' });
            });
    }

    function formatarTipoProcesso(idTipoProcesso) {
        const tiposProcesso = {
            'RECUPERACAO_JUDICIAL': 'Recuperação Judicial',
            'FALENCIA': 'Falência',
            'PERICIA': 'Perícia',
            'PENHORA_DE_FATURAMENTO': 'Penhora de Faturamento',
            'INVENTARIO': 'Inventário'
        };

        return tiposProcesso[idTipoProcesso] || 'Problema ao obter o tipo de processo';
    }

    function handleChangeEmpresa(idEmpresa) {
        if (empresas) {
            for (let i = 0; i < empresas.length; i++) {
                if (empresas[i].id == idEmpresa) {
                    setSelectedProcesso({
                        ...selectedProcesso,
                        empresa: empresas[i],
                    });
                    break;
                }
            }
        }
    }

    const handlePecasProcessuaisChange = (e) => {
        clearMessages();
        const files = Array.from(e.target.files);
        setSelectedFilesPecas(files);
    };

    const handleRelatorioAtividadeChange = (e) => {
        clearMessages();
        const files = Array.from(e.target.files);
        setSelectedFilesRelatorioAtividade(files);
    };

    function handleAddPecasProcessuais() {
        clearMessages();
        if(!arquivoPecasJaExiste()){
            let updated = pecasProcessuaisFiles;
            updated = updated.concat(selectedFilesPecas);
            setPecasProcessuaisFiles(updated);
        }
    }

    function handleAddRelatorioAtividade() {
        clearMessages();
        if(!arquivoRelatorioAtividadeJaExiste()){
            let updated = relatorioAtividadeFiles;
            updated = updated.concat(selectedFilesRelatorioAtividade);
            setRelatorioAtividadeFiles(updated);
        }
    }

    function arquivoPecasJaExiste(){
        for(let i=0; i<selectedFilesPecas.length; i++){
            for(let j=0; j<pecasProcessuaisFiles.length; j++) {
                if(pecasProcessuaisFiles[j].name === selectedFilesPecas[i].name){ 
                    setMsgErro('O arquivo '+selectedFilesPecas[i].name+' já foi adicionado ao processo!', setMsgGeral);
                    window.scrollTo(0,0);
                    return true;
                }
            }
            for(let j=0; j<selectedPecas.length; j++) {
                if(selectedPecas[j].nome === selectedFilesPecas[i].name){ 
                    setMsgErro('O arquivo '+selectedFilesPecas[i].name+' já foi adicionado ao processo!', setMsgGeral);
                    window.scrollTo(0,0);
                    return true;
                }
            }
        }
        return false;
    }

    function arquivoRelatorioAtividadeJaExiste(){
        for(let i=0; i<selectedFilesRelatorioAtividade.length; i++){
            for(let j=0; j<relatorioAtividadeFiles.length; j++) {
                if(relatorioAtividadeFiles[j].name === selectedFilesRelatorioAtividade[i].name){
                    setMsgErro('O arquivo '+selectedFilesRelatorioAtividade[i].name+' já existe no processo!', setMsgGeral);
                    window.scrollTo(0,0);
                    return true;
                }
            }
            for(let j=0; j<selectedRelatorioAtividade.length; j++) {
                if(selectedRelatorioAtividade[j].nome === selectedFilesRelatorioAtividade[i].name){
                    setMsgErro('O arquivo '+selectedFilesRelatorioAtividade[i].name+' já existe no processo!', setMsgGeral);
                    window.scrollTo(0,0);
                    return true;
                }
            }
        }
        return false;
    }

    const handleSaveProcesso = async (doUpload) => {
        clearMessages();
        if (!selectedProcesso.numProcesso) {
            setMsgGeral({ msg: 'Informe o número do processo!' });
            return;
        }

        if (!selectedProcesso.comarca) {
            setMsgGeral({ msg: 'Informe uma comarca!' });
            return;
        }

        if (!selectedProcesso.empresa.id) {
            setMsgGeral({ msg: 'Informe a empresa!' });
            return;
        }

        if (!selectedProcesso.tipoProcesso) {
            setMsgGeral({ msg: 'Informe o tipo de processo!' });
            return;
        }

        if (selectedProcesso.id) {
            axios.put(urlHost + endpointProcesso, selectedProcesso, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleProcessoResponse(response);
                })
                .catch(error => {
                    handleProcessoErrorResponse(error);
                });
        } else {
            axios.post(urlHost + endpointProcesso, selectedProcesso, getAuthConfigHeaders(cookies))
                .then(response => {
                    handleProcessoResponse(response);
                })
                .catch(error => {
                    handleProcessoErrorResponse(error);
                });
        }
    };

    const handleProcessoResponse = async (response) => {
        if (response.status === 200 || response.status === 201) {
            if (response.data) {
                setSelectedProcesso(response.data);

                if(await uploadFiles(response.data.id)){
                    setMsgGeral({ msg: 'Salvo com sucesso!', variant: 'info' });
                    setShowModal(false);
                    loadProcessos();
                }
            } else {
                setMsgGeral({ msg: 'Sem resposta do servidor. Tente novamente em alguns instantes.' });
            }
        } else {
            setMsgGeral({ msg: 'Erro: ' + response.data });
        }
    }

    const handleProcessoErrorResponse = (error) => {
        if (error.response.data.length > 0) {
            setMsgGeral({ msg: 'Erro: ' + error.response.data });
        } else {
            setMsgGeral({ msg: 'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.' });
        }
    }

    async function uploadFiles(idProcesso){
        clearMessages();
        try {
            let headers = { 'Content-Type': 'multipart/form-data' };

            if(pecasProcessuaisFiles.length>0){
                let formData = new FormData();
                for (let i = 0; i < pecasProcessuaisFiles.length; i++) {
                    formData.append('arquivo', pecasProcessuaisFiles[i]);
                }

                const response1 = await axios.post(urlHost + endpointArquivoProcesso + '/'+idProcesso+'/pecas', formData, getAuthConfigHeaders(cookies, headers));
                if (response1.status === 201 || response1.status === 200) {
                    
                }else {
                    setMsgGeral({ msg: 'Erro ao enviar arquivos: ' + response1.data });
                    return false;
                }
            }

            if(relatorioAtividadeFiles.length>0){
                let formData = new FormData();

                for (let i = 0; i < relatorioAtividadeFiles.length; i++) {
                    formData.append('arquivo', relatorioAtividadeFiles[i]);
                }

                const response2 = await axios.post(urlHost + endpointArquivoProcesso + '/'+idProcesso+'/relatorio-atividade', formData, getAuthConfigHeaders(cookies, headers));
                if (response2.status === 201 || response2.status === 200) {

                }else {
                    setMsgGeral({ msg: 'Erro ao enviar arquivos: ' + response2.data });
                    return false;
                }
            }
            return true;
        } catch (e) {
            if (e.response.data) {
                setMsgGeral({ msg: 'Erro ao enviar arquivos: ' + e.response.data.message });
            } else {
                setMsgGeral({ msg: 'Erro ao enviar arquivos: ' + e.message });
            }
            return false;
        }
    }

    function handleDeleteArquivoProcesso(){
        clearMessages();
        if(selectedArquivo.id){
            axios.delete(urlHost + endpointArquivo + '/' + selectedArquivo.id, getAuthConfigHeaders(cookies))
            .then(response => {
                setShowFileDeleteConfirmationModal(false);
                if (response.status === 200 || response.status === 201) {
                    if (response.data) {

                        if(selectedArquivo.tipo===0){
                            const pecasOrig = selectedPecas;
                            pecasOrig.splice(selectedArquivo.index, 1);
                            setSelectedPecas(pecasOrig);
                        }else if(selectedArquivo.tipo===1){
                            const orig = selectedRelatorioAtividade;
                            orig.splice(selectedArquivo.index, 1);
                            setSelectedRelatorioAtividade(orig);
                        }

                        setMsgGeral({ msg: 'Arquivo excluído com sucesso!', variant: 'info' });
                    } else {
                        setMsgGeral({msg: 'Sem resposta do servidor. Tente novamente em alguns instantes.'});
                    }
                } else {
                    setMsgGeral({ msg: 'Erro: ' + response.data });
                }
            })
            .catch(error => {
                setShowFileDeleteConfirmationModal(false);
                if (error.response.data.length > 0) {
                    setMsgGeral({ msg: 'Erro: ' + error.response.data });
                } else {
                    setMsgGeral({ msg: 'Ocorreu um erro desconhecido. Tente novamente em alguns instantes.' });
                }
            });
        }else{
            if(selectedArquivo.tipo===0){
                const pecasOrig = pecasProcessuaisFiles;
                pecasOrig.splice(selectedArquivo.index, 1);
                setPecasProcessuaisFiles(pecasOrig);
            }else if(selectedArquivo.tipo===1){
                const orig = relatorioAtividadeFiles;
                orig.splice(selectedArquivo.index, 1);
                setRelatorioAtividadeFiles(orig);
            }
            setShowFileDeleteConfirmationModal(false);
        }
    }

    function resetSelectedProcesso(){
        setSelectedProcesso(processoBase);
    }

    function resetFilesLists(){
        setPecasProcessuaisFiles([]);
        setRelatorioAtividadeFiles([]);
        setSelectedFilesPecas([]);
        setSelectedFilesRelatorioAtividade([]);
        setSelectedPecas([]);
        setSelectedRelatorioAtividade([]);
    }

    function clearMessages(){
        setMsgGeral({msg: ''});
    }

    function handleCancelar(){
        clearMessages();
        loadProcessos();
        setShowModal(false);
    }

    const handleSaveMensagemAlerta = async () => {
        if (selectedProcesso.id) {
            axios.post(urlHost + endpointProcesso + '/' + selectedProcesso.id + endpointMensagemAlerta,
                selectedProcesso.mensagemAlerta,
                getAuthConfigHeaders(cookies))
                .then(response => {
                    setShowMensagemAlertaModal(false);
                }).catch(error => {
                    handleProcessoErrorResponse(error);
                });
        }
    };

    useEffect(() => {
        loadProcessos();
        loadEmpresas();
    }, []);

    const isAtivoDisabled = !selectedProcesso.mensagemAlerta.titulo || !selectedProcesso.mensagemAlerta.descricao;

    return (
        <Container>
            <Row>
                <Col>
                    {msgGeral.msg.length > 0 ?
                        <Alert variant={msgGeral.variant ? msgGeral.variant : 'danger'} onClose={() => {clearMessages();}} dismissible>
                            {msgGeral.msg}
                        </Alert>
                        : null
                    }
                </Col>
            </Row>
            {!showModal ?
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="card admin-card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Processos
                                <Button variant="primary" onClick={handleAddProcesso}>
                                    Adicionar Processo
                                </Button>
                            </div>
                            <div className="card-body">
                                <Table hover responsive className="mt-3 table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '250px' }}>Nº do Processo</th>
                                            <th>Empresa</th>
                                            <th>Comarca</th>
                                            <th style={{ width: '200px' }}>Tipo</th>
                                            <th style={{ width: '100px' }}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {processos.map((processo) => (
                                            <tr key={processo.id}>
                                                <td>{processo.numProcesso}</td>
                                                <td>{processo.empresa ? processo.empresa.nome : ''}</td>
                                                <td>{processo.comarca}</td>
                                                <td>{formatarTipoProcesso(processo.tipoProcesso)}</td>
                                                <td>
                                                    <Button
                                                        variant="link"
                                                        onClick={() => handleEditProcesso(processo)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Button
                                                        variant="link"
                                                        onClick={() => showDeleteConfirmation(processo)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="card admin-card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                {selectedProcesso.id ? 'Dados do Processo' : 'Cadastro de Processo'}
                            </div>
                            <div className="card-body">
                                <Form className='form-admin'>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formNumProcesso" className='form-admin-group'>
                                                <Form.Label>Nº do Processo</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedProcesso.numProcesso}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            numProcesso: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formComarca"  className='form-admin-group'>
                                                <Form.Label>Comarca</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedProcesso.comarca}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            comarca: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formTipoProcesso" className='form-admin-group'>
                                                <Form.Label>Tipo de Processo</Form.Label>
                                                <Form.Select
                                                    placeholder="Selecione uma tipo de processo" required
                                                    value={selectedProcesso.tipoProcesso}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            tipoProcesso: e.target.value,
                                                        })
                                                    }
                                                >
                                                    <option value="RECUPERACAO_JUDICIAL">Recuperação Judicial</option>
                                                    <option value="FALENCIA">Falência</option>
                                                    <option value="PENHORA_DE_FATURAMENTO">Penhora de Faturamento</option>
                                                    <option value="INVENTARIO">Inventário</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formEmpresa"  className='form-admin-group'>
                                                <Form.Label>Empresa</Form.Label>
                                                <Form.Select
                                                    placeholder="Selecione uma Empresa" required
                                                    value={selectedProcesso.empresa.id}
                                                    onChange={(e) => {
                                                        handleChangeEmpresa(e.target.value)
                                                    }}>
                                                    <option value={{}}></option>
                                                    {empresas.map((empresa) => (
                                                        <option key={empresa.id} value={empresa.id}>{empresa.nome}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formFaseAtual" className='form-admin-group'>
                                                <Form.Label>Fase Atual</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={selectedProcesso.faseAtual}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            faseAtual: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formApresentacaoHabili" className='form-admin-group'>
                                                <Form.Label>Prazo final para apresentação de habilitações e divergências administrativas</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="datepic"
                                                    placeholder="DateRange"
                                                    value={selectedProcesso.prazoApresentacao.habilitacoesDivergenciasAdm}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            prazoApresentacao: {
                                                                ...selectedProcesso.prazoApresentacao,
                                                                habilitacoesDivergenciasAdm: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formApresentacaoImpugnacao" className='form-admin-group'>
                                                <Form.Label>Prazo final para apresentação de impugnação de crédito</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="datepic"
                                                    placeholder="DateRange"
                                                    value={selectedProcesso.prazoApresentacao.impugnacaoCredito}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            prazoApresentacao: {
                                                                ...selectedProcesso.prazoApresentacao,
                                                                impugnacaoCredito: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formApresentacaoObjecoes" className='form-admin-group'>
                                                {selectedProcesso.tipoProcesso !== "FALENCIA" && (<Form.Label>Prazo final para apresentação de objeções ao plano de recuperação judicial</Form.Label>)}
                                                {selectedProcesso.tipoProcesso === "FALENCIA" && (<Form.Label>Data da decretação da falência</Form.Label>)}
                                                <Form.Control
                                                    type="date"
                                                    name="datepic"
                                                    placeholder="DateRange"
                                                    value={selectedProcesso.prazoApresentacao.objecoesPlanoRecupJudicial}
                                                    onChange={(e) =>
                                                        setSelectedProcesso({
                                                            ...selectedProcesso,
                                                            prazoApresentacao: {
                                                                ...selectedProcesso.prazoApresentacao,
                                                                objecoesPlanoRecupJudicial: e.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="formArquivos" className='form-admin-group'>
                                                <Form.Label>Arquivos</Form.Label>
                                                <Accordion defaultActiveKey={['0']} alwaysOpen className='custom-accordion-admin'>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Peças Processuais</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    type="file"
                                                                    accept={extPermitidasProcesso}
                                                                    multiple
                                                                    onChange={handlePecasProcessuaisChange}
                                                                />
                                                                <Button variant="primary" onClick={handleAddPecasProcessuais} disabled={selectedFilesPecas.length===0}>
                                                                    Adicionar
                                                                </Button>
                                                            </InputGroup>

                                                            {pecasProcessuaisFiles.map((arquivo, index) => (
                                                                <div className='processo-file processo-new-file' key={index}>
                                                                    {arquivo.name}
                                                                    <Button variant='link' className='processo-file-delete'
                                                                        onClick={() => {showFileDeleteConfirmation(arquivo.name, null, 0, index)}}>
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Button>
                                                                </div>
                                                            ))}

                                                            {selectedPecas.map((arquivo, index) => (
                                                                <div className='processo-file processo-old-file' key={arquivo.id}>
                                                                    <a href={urlHostBase+repProcessos+'/'+arquivo.nome} target="_blank" rel="noreferrer">{formataNomeArquivo(arquivo.nome)}</a>
                                                                    <Button variant='link' className='processo-file-delete'
                                                                        onClick={() => {showFileDeleteConfirmation(arquivo.nome, arquivo.id, 0, index)}}>
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Button>
                                                                </div>
                                                            ))}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Relatórios de Atividade</Accordion.Header>
                                                        <Accordion.Body>
                                                                <InputGroup className="mb-3">
                                                                    <Form.Control
                                                                        type="file"
                                                                        accept={extPermitidasProcesso}
                                                                        multiple
                                                                        onChange={handleRelatorioAtividadeChange}
                                                                    />
                                                                    <Button variant="primary" onClick={handleAddRelatorioAtividade} disabled={selectedFilesRelatorioAtividade.length===0}>
                                                                        Adicionar
                                                                    </Button>
                                                                </InputGroup>
                                                                {relatorioAtividadeFiles.map((arquivo, index) => (
                                                                    <div className='processo-file processo-new-file' key={index}>
                                                                        {arquivo.name}
                                                                        <Button variant='link' className='processo-file-delete' 
                                                                            onClick={() => {showFileDeleteConfirmation(arquivo.name, null, 1, index)}}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                                {selectedRelatorioAtividade.map((arquivo, index) => (
                                                                    <div className='processo-file processo-old-file' key={arquivo.id}>
                                                                        <a href={urlHostBase+repProcessos+'/'+arquivo.nome} target="_blank" rel="noreferrer">{formataNomeArquivo(arquivo.nome)}</a>
                                                                        <Button variant='link' className='processo-file-delete' 
                                                                            onClick={() => {showFileDeleteConfirmation(arquivo.nome, arquivo.id, 1, index)}}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="card-footer text-end">
                                <Button variant="secondary" onClick={handleCancelar} style={{marginRight: '0.5rem'}}>
                                    Cancelar
                                </Button>
                                {selectedProcesso.id > 0 && (
                                    <Button onClick={() => showMensagemAlerta(selectedProcesso)} style={{ marginRight: '0.5rem', background: '#02368f' }}>
                                        Mensagem Alerta
                                    </Button>
                                )}
                                <Button variant="primary" onClick={handleSaveProcesso}>
                                    Salvar
                                </Button>                               
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir o processo <b>{selectedProcesso.id}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteProcesso()}>Sim</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMensagemAlertaModal} onHide={() => setShowMensagemAlertaModal(false)} centered backdrop="static">
                <Modal.Header className='modal-header-custom' closeButton>
                    <Modal.Title className='modal-title-custom'>Mensagem de Alerta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="formNumProcesso" className='form-admin-group'>
                                <Form.Label>Tĩtulo da Mensagem:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={selectedProcesso.mensagemAlerta.titulo}
                                    onChange={(e) =>
                                        setSelectedProcesso({
                                            ...selectedProcesso,
                                            mensagemAlerta:{
                                                ...selectedProcesso.mensagemAlerta,
                                                titulo: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Form.Group controlId="formNumProcesso" className='form-admin-group'>
                                <Form.Label>Descrição da Mensagem:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={7}
                                    maxLength={850}
                                    type="text"
                                    value={selectedProcesso.mensagemAlerta.descricao}
                                    onChange={(e) =>
                                        setSelectedProcesso({
                                            ...selectedProcesso,
                                            mensagemAlerta:{
                                                ...selectedProcesso.mensagemAlerta,
                                                descricao: e.target.value,
                                            }
                                        })
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Form.Group controlId="formAtivo" className='form-admin-group'>
                                <Form.Label>Ativo:</Form.Label>
                                <Form.Switch
                                    id="ativoSwitch"
                                    label=""
                                    checked={selectedProcesso.mensagemAlerta.ativo}
                                    disabled={isAtivoDisabled}
                                    onChange={(e) => setSelectedProcesso({
                                        ...selectedProcesso,
                                        mensagemAlerta: {
                                            ...selectedProcesso.mensagemAlerta,
                                            ativo: e.target.checked
                                        }
                                    })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowMensagemAlertaModal(false)}>Cancelar</Button>
                    <Button variant="primary" disabled={isAtivoDisabled} onClick={() => handleSaveMensagemAlerta()}>Salvar</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showFileDeleteConfirmationModal} onHide={() => setShowFileDeleteConfirmationModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Excluir</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tem certeza que deseja excluir o arquivo <b>{formataNomeArquivo(selectedArquivo.nome)}</b>?</p>
                    <p>Ao confirmar, esta ação não poderá ser desfeita.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFileDeleteConfirmationModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={() => handleDeleteArquivoProcesso()}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Processos;