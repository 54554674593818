import React, { useEffect, useState } from 'react';
import { Button, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Usuarios from './Usuarios';
import Modelos from './Modelos';
import { LinkContainer } from 'react-router-bootstrap'
import Escritorios from './Escritorios';
import Empresas from './Empresas';
import Processos from './Processos';
import Documentos from './Documentos';
import Equipe from './Equipe';
import logo from '../../../../resources/img/logo-dias-nogueira.webp';
import { MINUTE_MS, endpointEnvioDocumentoNotificacao, nomeJWTUsuario, urlHost } from '../../../../utils/Constants';
import { useCookies } from 'react-cookie';
import getAuthConfigHeaders from '../../../../utils/AuthUtils';
import axios from 'axios';

function AdminTemplate() {
  const [cookies, removeCookie,setCookie] = useCookies(nomeJWTUsuario);
  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [novosDocumentos, setNovosDocumentos] = useState([]);
  const [showToastEnvios, setShowToastEnvios] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  function handleLogout() {
    removeCookie(nomeJWTUsuario, {path:'/'});
    setCookie(nomeJWTUsuario, {
      ...cookies[nomeJWTUsuario],
      maxAge: 0
    })
    navigate('/login');
  }

  function handleVisualizarEnvios(){
    setShowToastEnvios(false);
    navigate('/admin/documentos');
  }

  function handleResize() {
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }

  function checkLogin(){
    if(!cookies[nomeJWTUsuario]){
      handleLogout();
    }
  }

  function loadNovosEnvios() {
    axios.get(urlHost + endpointEnvioDocumentoNotificacao, getAuthConfigHeaders(cookies))
      .then(response => {
        if (response.status === 200) {
          setNovosDocumentos(response.data);
          setShowToastEnvios(response.data && response.data.length>0);
        }
      }).catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    checkLogin();
    handleResize();
    window.addEventListener('resize', handleResize);
    loadNovosEnvios();//chamada inicial

    //chamadas subsequentes para notificar
    const interval = setInterval(() => {
      loadNovosEnvios();
    }, MINUTE_MS);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <>
      <ToastContainer className="p-3" position='top-end' style={{ zIndex: 1, position: 'fixed' }}>
        <Toast show={showToastEnvios} onClose={() => {setShowToastEnvios(false)}} className='admin-toast'>
          <Toast.Header>
            <strong className="me-auto">Envio de documentos</strong>
          </Toast.Header>
          <Toast.Body><Button variant='link' onClick={handleVisualizarEnvios}>Você possui {novosDocumentos.length} {'envio(s) de documento'} pendentes de visualização.</Button></Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="wrapper">
        <nav id="sidebar" className={isSidebarOpen ? 'admin-navbar' : "admin-navbar active"}>
          <div className="sidebar-header">
            <img src={logo} alt="bootraper logo" className="app-logo" />
          </div>
          <ul className="list-unstyled components text-secondary">
            <LinkContainer to="/admin/documentos">
              <li>
                <a href="#"><i className="fas fa-file-import"></i> Documentos</a>
              </li>
            </LinkContainer>
            <LinkContainer to="/admin/processos">
              <li>
                <a href="#"><i className="fas fa-folder-open"></i> Processos</a>
              </li>
            </LinkContainer>
            <LinkContainer to="/admin/usuarios">
              <li>
                <a href="#"><i className="fas fa-user-friends"></i> Usuários</a>
              </li>
            </LinkContainer>
            <LinkContainer to="/admin/modelos">
              <li>
                <a href="#"><i className="fas fa-file-export"></i> Modelos</a>
              </li>
            </LinkContainer>
            <LinkContainer to="/admin/escritorios">
              <li>
                <a href="#"><i className="fas fa-building"></i> Escritórios</a>
              </li>
            </LinkContainer>
            <LinkContainer to="/admin/empresas">
              <li>
                <a href="#"><i className="fas fa-building-columns"></i> Empresas</a>
              </li>
            </LinkContainer>
            <LinkContainer to="/admin/equipe">
              <li>
                <a href="#"><i className="fas fa-users"></i> Equipe</a>
              </li>
            </LinkContainer>
          </ul>
          <div className="admin-sidebar-footer">
            <ul className="list-unstyled components text-secondary">
              <li onClick={() => { setShowLogoutConfirmationModal(true) }}>
                <a href="#"><i className="fas fa-right-to-bracket"></i> Sair</a>
              </li>
            </ul>
          </div>
        </nav>
        <div id="body" className="active">
          <nav className="admin-navbar navbar navbar-expand-lg navbar-white bg-white">
            <Button variant='link' id="sidebarCollapse" className="btn btn-light admin-navbar-toggle" onClick={() => { toggleSidebar(); }}>
              <i className="fas fa-bars"></i>
            </Button>
          </nav>
          <div className="content">
            <div className="admin-content">
              <Routes>
                <Route path="/" exact element={<Documentos />} />
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/modelos" element={<Modelos />} />
                <Route path="/escritorios" element={<Escritorios />} />
                <Route path="/empresas" element={<Empresas />} />
                <Route path="/processos" element={<Processos />} />
                <Route path="/documentos" element={<Documentos />} />
                <Route path="/equipe" element={<Equipe />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showLogoutConfirmationModal} onHide={() => setShowLogoutConfirmationModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Sair</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja sair?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutConfirmationModal(false)}>Não</Button>
          <Button variant="primary" onClick={() => handleLogout()}>Sim</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminTemplate;